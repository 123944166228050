import video from "./video.mp4";

function App() {
  return (
    <>
      <div className="App w-screen h-screen bg-black">
        <header className="App-header">
          <video autoPlay loop muted>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </header>
      </div>
      {/* <div className="w-screen h-screen bg-black flex items-center justify-center">
        <img src="/bakimda.webp" alt="Nilrio" className="object-contain" />
      </div> */}
    </>
  );
}

export default App;
